/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Badge,
  Button
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import UserDetails from "../components/user-profile-lite/UserDetails";
import UserAccountDetails
  from "../components/user-profile-lite/UserAccountDetails";

class GamesPage extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {
    return (<Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="Zodpovědná osoba" subtitle="about" md="12"
                   className="ml-sm-auto mr-sm-auto"/>
      </Row>
      <Row>
        <Col lg="4">
          <UserDetails/>
        </Col>
      </Row>

    </Container>)
  }
}

export default GamesPage;
