/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import {
  Container,
  Row,
} from "shards-react";

import PageTitle from "../components/common/PageTitle";


class WelcomePage extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Úvod" subtitle="Vítejte"
                     className="text-sm-left"/>
        </Row>

        <div style={{maxWidth: '50%'}}>
        Vítejte na hernizona.online. Tento projekt vznikl pro zachování některých oblíbených her, jako je např. Slovní Fotbal nebo Kris Kros. Hry jsou nabízené zcela zdarma a jejich zpoplatnění se neplánuje. Žádné prémiové účty ani roční předplatné.
        <br /><br />
        Projekt může fungovat díky reklamě, která generuje dostatečný zisk na provoz herního serveru. Portál je dílem jednoho člověka a je to jeho hobby vedle jeho zaměstnání a proto zde není důraz na úporné "zpeněžení" jako na jiných portálech.
          <br /><br />
          V případě dotazů mě neváhejte <a href={'/info'}>kontaktovat</a>. Rád zodpovím Vaše dotazy.
        </div>
      </Container>
    );
  }
}

export default WelcomePage;
