/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Badge,
  Button
} from "shards-react";


import AdSense from 'react-adsense';
import PageTitle from "../components/common/PageTitle";
import hit from './../utils/Hits';

const Games = require('./../data/games.json');
const _ = require('lodash');
const items = require('./items');

class GamesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // First list of posts.
      games: Games
    };

    this.redirect = this.redirect.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  redirect(id) {
    this.props.history.push(`/embeded/${id}`)
  }


  render() {
    console.log(items);
    const keys = Object.keys(items);

    return <>
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Vlastní tvorba" subtitle="Položky"
                     className="text-sm-left"/>
        </Row>
        <Row>
          {
            keys.map(productId => {
              const product = items[productId];
              if (product.image && product.price && product.title) {
                return this.renderItem({...items[productId], id: productId});
              }
            })
          }
        </Row>
      </Container>
    </>
  }

  renderItem(info) {
    return (


      <Col lg="3" md="6" sm="12" className="mb-4" key={'sf2019'}>
        <Card small className="card-post card-post--1">
          <div
            className="card-post__image"
            style={{
              backgroundImage: `url('${info.image}')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain'
            }}
          >
            <Badge
              pill
              className={`card-post__category bg-dark`}
            >
              Cena {info.price}Kč
            </Badge>
          </div>
          <CardBody>
            <h5 className="card-title" onClick={null}>
                    <span className="text-fiord-blue">
                      {info.title.substring(0, 100)}
                    </span>
              &nbsp;&nbsp;&nbsp;
              <span style={{float: 'right'}}
                    onClick={() => {
                      window.open(`https://www.amazon.de/dp/${info.id}`, "_blank");
                    }}
                    className="mb-2 mr-1 btn btn-success">
                      Amazon
                    </span>
            </h5>

          </CardBody>
        </Card>
      </Col>
    )
  }

}

export default GamesPage;
