export default function () {
  return [
    {
      title: "Úvod",
      htmlBefore: '<i class="material-icons">info</i>',
      to: "/uvod",
    },
    {
      title: "Hry",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/hry",
    },
    {
      title: "Kris Kros",
      to: "/kriskros",
      htmlBefore: '<i class="material-icons">account_tree</i>',
      htmlAfter: ""
    },
    {
      title: "Slovní Fotbal",
      to: "/slovnifotbal",
      htmlBefore: '<i class="material-icons">blur_circular</i>',
      htmlAfter: ""
    },
    {
      title: "Kontakt",
      to: "/info",
      htmlBefore: '<i class="material-icons">account_circle</i>',
      htmlAfter: ""
    },
    // {
    //   title: "Add New Post",
    //   htmlBefore: '<i class="material-icons">note_add</i>',
    //   to: "/add-new-post",
    // },
    // {
    //   title: "Forms & Components",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/components-overview",
    // },
    // {
    //   title: "Tables",
    //   htmlBefore: '<i class="material-icons">table_chart</i>',
    //   to: "/tables",
    // },
    // {
    //   title: "User Profile",
    //   htmlBefore: '<i class="material-icons">person</i>',
    //   to: "/user-profile-lite",
    // },
    // {
    //   title: "Errors",
    //   htmlBefore: '<i class="material-icons">error</i>',
    //   to: "/errors",
    // }
  ];
}
