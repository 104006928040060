import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  Progress
} from "shards-react";

const UserDetails = ({userDetails}) => (
  <Card small className="mb-4 pt-3">
    <CardHeader className="border-bottom text-center">
      <div className="mb-3 mx-auto">
        <img
          className="rounded-circle"
          src={userDetails.avatar}
          alt={userDetails.name}
          width="110"
        />
      </div>
      <h4 className="mb-0">{userDetails.name}</h4>
      <span className="text-muted d-block mb-2">{userDetails.jobTitle}</span>
      <span className="text-muted d-block mb-2">tel.: +420 608 161 822</span>
      <span
        className="text-muted d-block mb-2">e-mail: xfukam00@gmail.com</span>
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="px-4">
        <div className="progress-wrapper">
        </div>
      </ListGroupItem>
      <ListGroupItem className="p-4">
        <strong className="text-muted d-block mb-2">
          {userDetails.metaTitle}
        </strong>
        <span>{userDetails.metaValue}</span>
      </ListGroupItem>
    </ListGroup>
  </Card>
);

UserDetails.propTypes = {
  /**
   * The user details object.
   */
  userDetails: PropTypes.object
};

UserDetails.defaultProps = {
  userDetails: {
    name: "Michal Fuka",
    avatar: require("./../../images/avatars/avatar.svg"),
    jobTitle: "Human",
    performanceReportTitle: "Workload",
    performanceReportValue: 74,
    metaTitle: "Popis",
    metaValue:
      "Stránka vznikla jako rozcestník oblíbených her. Naleznete zde běžné odreagovačky, ale i hry, které byly ukončeny s koncem Flash Playeru. Jedná se například o Slovní Fotbal nebo hru Dobyvatel. Vzhledem k nečinnosti nejmenovaného portálu jsem se rozhodl přepsat i hru Kris Kros."
  }
};

export default UserDetails;
