const axios = require('axios');

const hit = (gameId) => {
    const href = window.location.href.toLowerCase();
    const protocol = href.startsWith('https') ? 'https' : 'http';
    axios.post(`${protocol}://back.hernizona.online/hit`, {
        game_id: `${gameId}`,
    }).then((response) => {
        console.log(`Hit OK for ${gameId}.`);
    }, (error) => {
        console.log(`Hit Error for ${gameId}.`);
    });
}

export default hit
