import React from "react";
import {Redirect} from "react-router-dom";

// Layout Types
import {DefaultLayout} from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import AboutPage from "./views/AboutPage";
import GamesPage from "./views/GamesPage";
import KarelPage from "./views/KarelPage";
import EmbededPage from "./views/EmbededPage";
import KrisKrosPage from "./views/KrisKrosPage";
import SlovniFotbalPage from "./views/SlovniFotbalPage";
import WelcomePage from "./views/WelcomePage";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/hry"/>
  },
  {
    path: "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/info",
    layout: DefaultLayout,
    component: AboutPage
  },
  {
    path: "/hry",
    layout: DefaultLayout,
    component: GamesPage
  },
  {
    path: "/uvod",
    layout: DefaultLayout,
    component: WelcomePage
  },
  {
    path: "/embeded/:id",
    layout: DefaultLayout,
    component: EmbededPage
  },
  {
    path: "/kriskros",
    layout: DefaultLayout,
    component: KrisKrosPage
  },
  {
    path: "/slovnifotbal",
    layout: DefaultLayout,
    component: SlovniFotbalPage
  },
  {
    path: "/karel",
    layout: DefaultLayout,
    component: KarelPage
  },
];
