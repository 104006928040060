/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import {
    Container,
} from "shards-react";
import hit from './../utils/Hits';

const Games = require('./../data/games.json');
const _ = require('lodash');

class GamesPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: null
        }
    }

    componentDidMount() {
        const pieces = window.location.href.split('/')
        const gameId = pieces[pieces.length - 1];

        hit(gameId);

        this.setState({
            id: gameId,
        })
    }

    render() {
        const game = _.find(Games, ['id', this.state.id]);

        if (!game) {
            return (<Container fluid className="main-content-container px-4">
                <br/>
                <h2 className="m-0">Nenalezeno</h2>
            </Container>)
        }

        return (
            <Container fluid className="main-content-container px-4">
                <h5>{game.name}</h5>
                <iframe
                    frameBorder="0"
                    style={{
                        width: "800px",
                        height: "400px",
                        "overflow": "hidden",
                        frameBorder: 0
                    }}
                    src={game.game_url}
                    scrolling="no">
                </iframe>
            </Container>
        );
    }

}

export default GamesPage;
